<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
  >
  <div slot="title">
      Исполнения
      <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
        <SaveIcon /> Сохранить
      </el-button>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="executionForm" :model="execution" label-width="12rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input v-model="execution.name"></el-input>
      </el-form-item>
      <el-form-item prop="code" label="Код:">
        <el-input v-model="execution.code"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import executionsApi from '@/api/nsi/executions';
import SaveIcon from '@/assets/icons/save.svg';

export default {
  name: 'ExecutionModal',
  props: ['value', 'execution'],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addExecution(execution) {
      await executionsApi.addExecution(execution);
      this.$emit('on-create');
    },
    async updateExecution(execution) {
      await executionsApi.updateExecution(execution);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.executionForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;

      const request = this.execution.id ? this.updateExecution : this.addExecution;
      this.loading = true;
      await request(this.execution);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
