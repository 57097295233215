<template>
  <div>
    <executions-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <executions-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import ExecutionsFilter from '@/views/Nsi/Executions/ExecutionsFilter';
import ExecutionsTable from '@/views/Nsi/Executions/ExecutionsTable';

export default {
  name: 'Executions',
  components: { ExecutionsTable, ExecutionsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
